<template>
  <div v-lazy-container="{ selector: 'img', loading: loader }" class="game-item position-relative mb-5 mx-1"
    :title="game.name" :class="game.system + '_game'">
    <img :alt="'MagnetBet игра ' + game.name" :data-src="game.picture" :src="game.picture" class="game-img">
    <div class="game-hovered">
      <div class="play-btn" @click.prevent.stop="gotoGameView(1)">
        <div class="play-icon">
          <i class="fa fa-play-circle"></i>
        </div>
      </div>
    </div>
    <div class="overlay position-absolute w-100 text-center hidden-bg game-info">
      <a class="playbtn py-2 mx-auto d-inline-block w-100 px-2" @click.prevent.stop="gotoGameView">
        <h6 class="my-0 px-1 game-title"> {{ game.name }} </h6>
      </a>
      <span :ref="game.system + '_' + game.id" class="add-game-to-favorites"
        :title="gameIsFavorite ? 'Удалить из избранных' : 'Добавить в избранные'"
        :class="gameIsFavorite ? 'is-favorite-game' : 'not-favorite-game'"
        @click.prevent.stop="gameIsFavorite ? removeGameFromFavorites(game) : addGameToFavorites(game)">
        <i v-if="!favoriteGameIsSubmitting" class="far fa-star"></i>
        <i v-else class="fa fa-spin fa-spinner"></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GamePreview',
  props: ['game', 'img'],
  data() {
    return {
      favoriteGameIsSubmitting: false,
      gameIsFavorite: false,
      loader: require('@/../public/images/icons/small-loader.svg')
    }
  },
  computed: {
    isMobile() {
      return this.$_config.environment.mobile
    }
  },
  mounted() {
    this.gameIsFavorite = this.game.is_favorite ? 1 : null
  },
  methods: {
    addGameToFavorites(game) {
      if (!this.$store.getters.userData) {
        this.$root.$emit('bv::show::modal', 'loginModal')
        return this.$root.$emit('reasonToLogin', 'Действия доступно авторизованным клиентам')
      }
      this.favoriteGameIsSubmitting = true

      this.axios.post(
        this.$_config.baseUrl + '/Api/addGameToFavorites', {
        game: game.id,
        system: game.system,
        params: JSON.stringify(game)
      },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then(({ data }) => {
        this.favoriteGameIsSubmitting = false

        if (data.status) {
          if (data.status === 'ok') {
            this.gameIsFavorite = true
            this.game.is_favorite = 1
            this.$root.$emit('addGameToFavorites', this.game)
          } else {
            this.$root.$emit('modalAlertMessage', { msg: data.msg, status: data.status })
            return this.$root.$emit('bv::show::modal', 'modalAlert')
          }
        } else {
          this.$root.$emit('modalAlertMessage', {
            msg: 'Действие не может быть выполнено.Пожалуйста, сообщите об этом в контактную службу',
            status: 'error'
          })
          return this.$root.$emit('bv::show::modal', 'modalAlert')
        }
      }).catch((err) => {
        if (err) {
          console.log(err)
        }
      })
    },
    removeGameFromFavorites(game) {
      if (!this.$store.getters.userData) {
        this.$root.$emit('bv::show::modal', 'loginModal')
        return this.$root.$emit('reasonToLogin', 'Действия доступно авторизованным клиентам')
      }
      this.favoriteGameIsSubmitting = true
      this.axios.post(
        this.$_config.baseUrl + '/Api/removeGameFromFavoriteList', {
        game: game.id,
        system: game.system
      },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then(({ data }) => {
        this.favoriteGameIsSubmitting = false

        if (data.status) {
          if (data.status === 'ok') {
            this.gameIsFavorite = false
            this.game.is_favorite = 0
            this.$root.$emit('removeGameFromFavorites', this.game)
          } else {
            this.$root.$emit('modalAlertMessage', { msg: data.msg, status: data.status })
            return this.$root.$emit('bv::show::modal', 'modalAlert')
          }
        } else {
          this.$root.$emit('modalAlertMessage', {
            msg: 'Действие не может быть выполнено.Пожалуйста, сообщите об этом в контактную службу',
            status: 'error'
          })
          return this.$root.$emit('bv::show::modal', 'modalAlert')
        }
      }).catch((err) => {
        if (err) {
          console.log(err)
        }
      })
    },
    gotoGameView(checkDemo) {

      this.$store.dispatch('fetchUserData')

      const userData = this.$store.getters.userData
      if (!userData) {
        this.$root.$emit('bv::show::modal', 'loginModal')
        return this.$root.$emit('reasonToLogin', 'Для запуска игры нужно авторизоваться')
      }
      const gameName = this.game.name.split(' ').join('-')

      return this.$router.push({
        name: 'LaunchGame',
        params: {
          gameName: gameName,
          gameId: this.game.id,
          game: this.game,
          provider: this.game.system,
          isLive: this.game.isLive,
          restoring: false,
          isDemo: checkDemo
        }
      })
    }
  },
  updated() {
    this.gameIsFavorite = this.game.is_favorite ? 1 : null
  }
}
</script>

<style>
.play-and-demo {
  width: max-content;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  gap: 15px
}

.play-and-demo button {
  background: none;
  color: white;
  border: none;
  border-radius: 5px;
  min-width: 7vh;
}

.game-item img[lazy='loading'] {
  object-fit: contain !important;
}

.game-hovered {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #000000d6;
  width: 100%;
  display: none;
}

.play-btn {
  width: 5em;
  height: 5em;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.play-icon {
  font-size: 40px;
}

.game-item {
  cursor: pointer;
}

.game-item:hover .game-hovered {
  display: block;
}

.game-item:hover .game-info {
  transition: 0.2s;
}

.not-favorite-game {
  color: #979797;
}

.is-favorite-game {
  color: #7eff15;
}

.is-favorite-game:hover {
  color: red;
}

.not-favorite-game:hover {
  color: #7eff15;
}

.fa-star-o:before {
  content: "\f006";
}

.add-game-to-favorites {
  cursor: pointer;
  float: right;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 14px;
  width: 30px;
  height: 30px;
  padding-top: 6px;
}

.game-info {
  background-color: #333333;
  top: 100%;
  height: 32px;
  overflow: hidden;
}

.game-item .game-title {
  color: white;
  max-width: 90%;
  width: 90%;
  height: 17px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.game-item .game-img {
  width: 100%;
  height: 190px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #333333;
  object-fit: contain;
  object-position: top;
  padding-top: 10px;
}

.game-item {
  min-height: 100px;
}

@media screen and (min-width: 2000px) {
  .game-item .game-img {
    height: 170px;
  }
}
</style>
<style scoped>
.fa-spinner {
  color: #ffffff;
  font-size: 14px;
}
</style>
